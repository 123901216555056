<template>
  <div class="user-manage-container">
    <el-card class="header table-search" v-if="false">
      <el-form :inline="true" :model="formInline" class="demo-form-inline">
        <el-form-item label="姓名">
          <el-input
            v-model="formInline.user"
            placeholder="请输入姓名"
            clearable
          />
        </el-form-item>
        <el-form-item label="角色">
          <el-select
            v-model="formInline.region"
            placeholder="请选择角色"
            clearable
          >
            <el-option label="管理员" value="shanghai" />
            <el-option label="超级管理员" value="beijing" />
          </el-select>
        </el-form-item>
        <el-form-item label="开通时间">
          <el-date-picker
            v-model="formInline.date"
            type="date"
            placeholder="请选择开通时间"
            clearable
          />
        </el-form-item>
        <el-form-item>
          <el-button type="primary" :icon="Search" @click="onSubmit"
            >查询</el-button
          >
          <el-button :icon="Delete" @click="reset"> 重置 </el-button>
        </el-form-item>
      </el-form>
    </el-card>
    <el-card>
      <div class="filter-container" v-if="false">
        <el-button type="primary" :icon="Promotion">
          打款
        </el-button>
        <el-button type="danger" plain disabled :icon="Delete">
          删除
        </el-button>
      </div>
      <el-table
        v-loading="loading"
        element-loading-text="正在加载中..."
        :data="tableData"
        border
        style="width: 100%"
        header-cell-class-name="table-header"
      >
        <el-table-column label="序号" type="index" align="center" width="70" />
        <!-- 姓名 -->
        <el-table-column prop="username" label="姓名" align="center">
        </el-table-column>
        <!-- 联系方式 -->
        <el-table-column prop="mobile" label="联系方式" align="center">
        </el-table-column>
        <!-- 头像 -->
        <el-table-column label="头像" align="center">
          <template v-slot="{ row }">
            <el-image
              class="avatar"
              :src="row.avatar"
              rounded
              style="width: 30px; height: 30px"
              :preview-src-list="[row.avatar]"
            ></el-image>
          </template>
        </el-table-column>
        <!-- 角色 -->
        <el-table-column label="角色" align="center">
          <template #default="{ row }">
            <div v-if="row.role && row.role.length > 0">
              <div class="flex gap-2">
                <el-tag v-for="item in row.role" :key="item.id">{{
                  item.title
                }}</el-tag>
              </div>
            </div>
            <div v-else>
              <el-tag size="mini">{{ $t('msg.excel.defaultRole') }}</el-tag>
            </div>
          </template>
        </el-table-column>
        <!-- 时间 -->
        <el-table-column label="开通时间" align="center">
          <template #default="{ row }">
            {{ $filters.dateFilter(row.openTime) }}
          </template>
        </el-table-column>
        <!-- 操作 -->
        <el-table-column label="操作" fixed="right" width="300" align="center">
          <template #default="{row}">
            <el-button
              type="primary"
              link
              :icon="Promotion"
              @click="onShowClick(row._id)"
              >打款</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination
        class="pagination"
        :background="true"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="page.pageIndex"
        :page-sizes="[2, 5, 10, 20]"
        :page-size="page.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="page.total"
      >
      </el-pagination>
    </el-card>
  </div>
</template>

<script setup>
import { ref, reactive, onActivated } from 'vue'
import { Delete, Search, Promotion } from '@element-plus/icons-vue'
import { settleList } from '@/api/account'
// import { ElMessage, ElMessageBox } from 'element-plus'

const formInline = reactive({
  user: '',
  region: '',
  date: ''
})
// 数据相关
const tableData = ref([])
const loading = ref(false)
const page = reactive({
  pageIndex: 1,
  pageSize: 10,
  total: 0
})

const onSubmit = () => {
  console.log('submit!')
}

const reset = () => {
  console.log('reset!')
}

// 获取数据的方法
const getListData = async () => {
  loading.value = true
  const result = await settleList({
    pageIndex: page.pageIndex,
    pageSize: page.pageSize
  })
  tableData.value = result.datas
  page.total = result.records
  loading.value = false
}
getListData()
// 处理导入用户后数据不重新加载的问题
onActivated(getListData)

// 分页相关
/**
 * size 改变触发
 */
const handleSizeChange = currentSize => {
  page.pageSize = currentSize
  getListData()
}
/**
 * 页码改变触发
 */
const handleCurrentChange = currentPage => {
  page.pageIndex = currentPage
  getListData()
}
</script>

<style lang="scss" scoped>
@import '~@/styles/mixin.scss';
.user-manage-container {
  .header {
    @include searchForm;
  }

  .filter-container {
    .el-button {
      margin-bottom: 15px;
    }
  }

  :deep(.table-header) {
    @include tableThead;
  }
  .pagination {
    @include pagination;
  }
}
</style>
